import { useState, useEffect } from "react";
import DzCpraForm from './DzCpraForm';
import DzClassification from './DzClassification';
import DzCommunicationPreferences from './DzCommunicationPreferences';
// import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  "display": "inline-block",
  "margin": "0 auto"
}


const DzCpraApp = () => {
  const [page, setPage] = useState(0);
  const [userType, setUserType] = useState(null);
  const [formType, setFormType] = useState(null);
  const [workerStatus, setWorkerStatus] = useState(null);
  const [issueType, setIssueType] = useState(null);
  const [loading, setLoading] = useState(true);



  // useEffect(() => {
  //   let pathname = window.location.pathname;
  //   if(pathname === "/privacy/cpra-request-to-know/"){
  //     setFormType("rtk-form");
  //     // setIssueType("Employee Request to Know");
  //   } else if (pathname === "/privacy/cpra-request-to-delete/") {
  //     setFormType("rtd-form");
  //     // setIssueType("Employee Request to Delete");
  //   } else if (pathname === "/privacy/cpra-request-to-correct/") {
  //     setFormType("rtc-form");
  //     // setIssueType("Employee Request to Correct");
  //   } else if (pathname === "/privacy/cpra-request-to-opt-out/") {
  //     setFormType("optOut-form");
  //     // setIssueType("Employee Request to Opt Out");
  //   } else if (pathname === "/preferences/") {
  //     setFormType("communication-preferences");
  //     setWorkerStatus("Consumer");
  //     setIssueType("Communication Preferences");
  //   }
  //   setLoading(false)
  // },[])

  useEffect(() => {
    let pathname = window.location.pathname;
    if(pathname === "/privacy/request-to-know/"){
      setFormType("rtk-form");
      // setIssueType("Employee Request to Know");
    } else if (pathname === "/privacy/request-to-delete/") {
      setFormType("rtd-form");
      // setIssueType("Employee Request to Delete");
    } else if (pathname === "/privacy/request-to-correct/") {
      setFormType("rtc-form");
      // setIssueType("Employee Request to Correct");
    } else if (pathname === "/privacy/request-to-opt-out/") {
      setFormType("optOut-form");
      // setIssueType("Employee Request to Opt Out");
    } else if (pathname === "/preferences/") {
      setFormType("communication-preferences");
      setWorkerStatus("Consumer");
      setIssueType("Communication Preferences");
    }
    setLoading(false)
  },[])


  const componentList = [
  <DzClassification
    page={page}
    setPage={setPage}
    userType={userType}
    setUserType={setUserType}
    formType={formType}
    setFormType={setFormType}
    workerStatus={workerStatus}
    setWorkerStatus={setWorkerStatus}
    issueType={issueType}
    setIssueType={setIssueType}
  />,
  <DzCpraForm
    page={page}
    setPage={setPage}
    userType={userType}
    setUserType={setUserType}
    formType={formType}
    setFormType={setFormType}
    workerStatus={workerStatus}
    setWorkerStatus={setWorkerStatus}
    issueType={issueType}
    setIssueType={setIssueType}
  />,
  <DzCommunicationPreferences
    formType={formType}
    workerStatus={workerStatus}
    issueType={issueType}
  />
];

  console.log(formType);
  return (
    <div className="">
      <ClipLoader color={'rgb(80, 115, 54)'} loading={loading} cssOverride={override} size={25} />
      {!loading &&
        <div>
          {formType === "communication-preferences" ? componentList[2] : componentList[page]}
        </div>
      }
    </div>
  );
};
export default DzCpraApp;
