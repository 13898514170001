export default function Input({ objValue, onChange, index, deleteField }) {
  const { label, type, value, firstname, lastname } = objValue;
  return (
    <div className="">
      <div className="form-group">
        <label>Property Owner 1 First Name:</label>
        <input
          type={type || "text"}
          className="form-control form-control-lg"
          id={label}
          value={firstname || ""}
          onChange={(e) => onChange(e, index)}
        />
      </div>
      <div className="form-group">
        <label>Property Owner 1 Last Name:</label>
        <input
          type={type || "text"}
          className="form-control form-control-lg"
          id={label}
          value={lastname || ""}
          onChange={(e) => onChange(e, index)}
        />
        </div>
        {/* Add this */}
        <div onClick={(e) => deleteField(e, index)}>X</div>
    </div>
  );
}
