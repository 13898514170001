import React from 'react';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { label: props.label, changeHandler: props.onChange, value: props.value, name: props.name }
  }


  render(){
    // let label = this.state.label
    return (
      <div className="ui form" style={this.props.style}>
        <div className="grouped fields">
          <div className="field">
            <div className="ui checkbox">
            <input
              type="checkbox"
              id={this.props.id}
              className={this.props.className}
              name={this.props.name}
              onChange={this.props.onChange}
               />
            <label className="align-top" style={{"margin-left": "5px", "display" : "inline"}}><strong>{this.props.label}</strong></label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Checkbox;
