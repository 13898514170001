const { render } = wp.element; //we are using wp.element here!
import BuybackForm from './BuybackForm';
import DzCpraApp from './DzCpraApp';
import App from './App';
// if (document.getElementById('my-react-app')) { //check if element exists before rendering
//   render(<BuybackForm />, document.getElementById('my-react-app'));
// }
// DzCpra PROD
if (document.getElementById('cpra-app')) { //check if element exists before rendering
  render(<DzCpraApp />, document.getElementById('cpra-app'));
}
// CPRA local
// if (document.getElementById('my-react-app')) { //check if element exists before rendering
//   render(<App />, document.getElementById('my-react-app'));
// }
// CPRA Forms PROD
// if (document.getElementById('cpra-app')) { //check if element exists before rendering
//   render(<App />, document.getElementById('cpra-app'));
// }
