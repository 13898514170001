import { useState, useEffect } from "react";

const Classification = ({page, setPage, userType, setUserType, formType, setFormType, workerStatus, setWorkerStatus}) => {
  const button = {
    "margin": "30px",
    "font-size": "2rem",
    "background": "#A6BD65",
    // "background": "#517633",
    "color": "#FFFFFF",
    "padding": "15px 30px",
    "cursor": "pointer"
  }
  const drkButton = {
    "background": "rgba(41, 62, 26, 1)",
    "margin": "30px",
    "font-size": "2rem",
    "color": "#FFFFFF",
    "padding": "15px 30px",
    "cursor": "pointer"
  }


  // console.log(window.location.pathname);
  return (
    <div className="">
      <div className="text-center"><p>Please select one of the below classifications.</p></div>
      <div className="row text-center justify-content-center">
          {formType === "optOut-form"
            ? <div className="col-sm-12 col-lg-6">
                <div style={drkButton} onClick={() => {setPage(page + 1); setWorkerStatus('Consumer')}}>
                    I am an existing or potential client
                </div>
                <div style={button} onClick={() => {setPage(page + 1); setUserType('Worker')}}>
                    I am a current or past worker, vendor or job applicant
                </div>
              </div>
            : <div className="col-sm-12 col-lg-6">
                <div style={drkButton} onClick={() => {setPage(page + 1); setWorkerStatus('Consumer')}}>
                    I am an existing client
                </div>
                <div style={drkButton} onClick={() => {setPage(page + 1); setWorkerStatus('Consumer')}}>
                    I am a potential client
                </div>
                <div style={button} onClick={() => {setPage(page + 1); setUserType('Worker'); setWorkerStatus('Current Employee')}}>
                    I am a current or past worker
                </div>
                <div style={button} onClick={() => {setPage(page + 1); setUserType('Vendor'), setWorkerStatus('Vendor')}}>
                    I am a current or past vendor
                </div>
                <div style={button} onClick={() => {setPage(page + 1); setUserType('Job Applicant'), setWorkerStatus('Job Applicant')}}>
                    I am a past job applicant
                </div>
              </div>
          }
      </div>
    </div>
  );
};

export default Classification;
