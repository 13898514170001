const { useState, useEffect, useCallback, CSSProperties } = wp.element;
import { Form } from 'react-bootstrap';
import Checkbox from './Checkbox';
import axios from 'axios';
const SITE_KEY = "6LdOl6cUAAAAAMl_z4wEwQWjZoSAz6QZ3DuRjKtc";
import NumberFormat from 'react-number-format';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "inline-block",
  margin: "0 10px",
  verticalAlign: "top"
}

const CommunicationPreferences = ({page, setPage, userType, setUserType, formType, workerStatus, setWorkerStatus, issueType, setIssueType}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isSelected, setIsSelected] = useState('active');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [noCall, setNoCall] = useState('No');
  const [noText, setNoText] = useState('No');
  const [noEmail, setNoEmail] = useState('No');
  const [noMail, setNoMail] = useState('No');
  const [noContact, setNoContact] = useState('No');
  const [zipcode, setZipcode] = useState('');
  const [validated, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);


  // console.log(issueType);
  // console.log(formType);

  const sizes = {
    mobile: '600px',
    tablet: '768px'
  }
  const devices = {
    mobile: `(max-width: ${sizes.mobile})`,
    tablet: `(max-width: ${sizes.tablet})`
  }

  const formStyle = {
    'border': '1px solid #c5c5c5',
    'background-color': '#f2f3f3',
    'padding': '2.2rem',
    'text-align' : 'left'
  }
  const messageStyle = {
    margin: "1em auto",
    padding: "0.2em 1em",
    border: "2px solid #ffb900",
    };

  const primaryBtn = {
    'background-color': 'rgb(80, 115, 54)',
    'color': 'rgb(255, 255, 255)',
    'padding': '0.5rem 3rem',
  }

  const spinnerDiv = {
    "display": "inline-block",
    "vertical-align": "middle",
    "margin": "10px",
  }


  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = () => {
    // setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        // console.log(token);
        submitData(token);
      });
    });
  }

  // Send reCAPTCHA token to server for verification
  const submitData = async (token) => {

      try {
        const result = await axios.post('https://forestlawn.com/wp-content/themes/forestlawn/recaptcha-react-forms.php', {
            // const result = await axios.post('http://forestlawn.web01.forestlawn.intra/wp-content/themes/forestlawn/recaptcha-react-forms.php', {
            // const result = await axios.post('http://localhost/forestlawn.com/wp-content/themes/forestlawn/recaptcha-react-forms.php', {
              token
            })
            // console.log(result.data.score);
            // sending the data to the wordpress backend to make the api call to Azure
            if(result.data.score > 0.5){
              try {
                const form = await axios.post('https://forestlawn.com/wp-content/themes/forestlawn/JiraCommunicationPreferences.php', {
                // const form = await axios.post('http://forestlawn.web01.forestlawn.intra/wp-content/themes/forestlawn/JiraCpra.php', {
                  // const form = await axios.post('http://localhost/forestlawn.com/wp-content/themes/forestlawn/JiraCpra.php', {
                    issueType,
                    firstname,
                    middleName,
                    lastname,
                    phone,
                    email,
                    workerStatus,
                    formType,
                    address,
                    city,
                    state,
                    zipcode,
                    noCall,
                    noText,
                    noEmail,
                    noMail,
                    noContact
                  })
                  // console.log('returned data:', form);
                  // const data = form.data;

                  if (form.data.key) {
                    window.open("/privacy/cpra-thank-you/", "_self");
                    setSuccess(true);
                    setLoading(false);
                  }
              } catch (e) {
                console.log(`axios request failed: ${e}`);
                setError(true);
                setLoading(false);
              }
            }
          } catch (e){
            setRecaptchaError(true);
            console.log(`axios request failed: ${e}`);
          }
  }

  const sendToJira = async (event) => {
    event.preventDefault()
    const checkForm = event.target; //PRODUCTION
    // const checkForm = event.currentTarget; //Development
    if (checkForm.checkValidity() !== false) {
      event.stopPropagation();
      setError(false);
      setLoading(true);
      // submitData()
      handleReCaptchaVerify();
    } else {
      setError(true);
    } //end of form validity check
    setValidation(true);
  }

  const checkAll = (e) => {
    let checkboxes = document.getElementsByName('noContact');
    if(e.target.checked === true){
      setNoContact('Yes');
        setNoCall('Yes');
        setNoText('Yes');
        setNoEmail('Yes');
        setNoMail('Yes');
        for (var i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true;
        }
    } else {
      setNoContact("No");
      setNoCall("No");
      setNoText("No");
      setNoEmail("No");
      setNoMail("No");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
  }

  return (
    <>
      <div class="row">
        <div class="text-center" style={{"margin": "0 auto 30px auto"}}>In accordance with California law, in order to comply with your request, we are required to verify your identity with the information below, and ask that you complete this form. Please provide your first and last names, email, and any additional contact information.</div>
      </div>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <Form id="cpra-form" class="text-sm-center text-lg-left" style={formStyle} noValidate validated={validated} onSubmit={sendToJira}>
            <div className="form-group">
              <label>First Name*:</label>
              <input className="form-control form-control-lg" type="text" value={firstname} onChange={(e) => setFirstName(e.target.value)} required/>
              <div className="invalid-feedback">
                  Please provide a first name.
              </div>
            </div>
              <div className="form-group">
                <label> Middle Name:</label>
                <input className="form-control form-control-lg" type="text" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
              </div>
            <div className="form-group">
              <label>Last Name*:</label>
              <input className="form-control form-control-lg" type="text" value={lastname} onChange={(e) => setLastName(e.target.value)} required/>
              <div className="invalid-feedback">
                Please provide a last name.
              </div>
            </div>
            <div className="form-group">
              <label>Phone Number*:</label>
              <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="(000) 000 - 0000" type="tel" value={phone} format="(###) ### - ####" onValueChange={(values) => {
                const { formattedValue, value } = values;
                setPhone(formattedValue);
              }} required/>
              <div className="invalid-feedback">
                Please provide a valid phone number.
              </div>
            </div>
            <div className="form-group">
              <label>Email Address*:</label>
              <input className="form-control form-control-lg" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
              <div className="invalid-feedback">
                Please provide a valid email.
              </div>
            </div>
            <div className="form-group">
              <label>Mailing Address:</label><br/>
              <input className="form-control form-control-lg" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
            </div>
            <div className="form-row">
              <div className="form-group col-md-5">
                <label>City:</label><br/>
                <input className="form-control form-control-lg" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
              </div>
              <div className="form-group col-md-4">
                <label>State:</label><br/>
                <input className="form-control form-control-lg" type="text" value={state} onChange={(e) => setState(e.target.value)} />
              </div>
              <div className="form-group col-md-3">
                <label>Zip Code:</label><br/>
                <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="00000" type="text" value={zipcode} format="#####" onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  // console.log(formattedValue);
                  setZipcode(formattedValue);
                }} />
              </div>
            </div>
               <div className="form-group" style={{"margin-top": "2rem"}}>
                <p>Communication Preferences</p>
                  <Checkbox
                    style={{"margin-top": "1rem"}}
                    name="noContact"
                    label="Do Not Call"
                    onChange={(e) => { e.target.checked ? setNoCall("Yes") : setNoCall("No") }}
                    checked={noCall === 'Yes'}
                  />
                  <Checkbox
                    style={{"margin-top": "1rem"}}
                    name="noContact"
                    label="Do Not Text"
                    onChange={(e) => { e.target.checked ? setNoText("Yes") : setNoText("No") }}
                    checked={noText === 'Yes'}
                  />
                  <Checkbox
                    style={{"margin-top": "1rem"}}
                    name="noContact"
                    label="Do Not Email"
                    onChange={(e) => { e.target.checked ? setNoEmail("Yes") : setNoEmail("No") }}
                    checked={noEmail === 'Yes'}
                  />
                  <Checkbox
                    style={{"margin-top": "1rem"}}
                    name="noContact"
                    label="Do Not Send Mail"
                    onChange={(e) => { e.target.checked ? setNoMail("Yes") : setNoMail("No") }}
                    checked={noMail === 'Yes'}
                  />
                  <Checkbox
                    style={{"margin-top": "1rem"}}
                    name="noContact"
                    label="Do Not Contact"
                    onChange={checkAll}
                    checked={noContact === 'Yes'}
                  />
                </div>
            <p class="margin-bottom-15">* required</p>

            <div id="recaptcha"></div>
            <div class="text-center">
              <button className="btn justify-content-center font-18" style={primaryBtn} type="submit">
                SUBMIT<ClipLoader color={'rgb(255, 255, 255)'} loading={loading} cssOverride={override} size={25} />
              </button>
            </div>
            <div style={spinnerDiv}>

            </div>
            <div className="error-message">
              {error &&
                <p style={messageStyle}>One or more fields have an error. Please check and try again.</p>
              }
            </div>
            <div>
              {recaptchaError &&
                <div><p style={messageStyle}>There is an error with your request. Please try again later.</p></div>
              }
            </div>
          </Form>
        </div>
        <div className="col-md-2"></div>
      </div>
    </>

  )
};

export default CommunicationPreferences;
