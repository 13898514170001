const { useState, useEffect, useCallback, CSSProperties } = wp.element;
import { Form } from 'react-bootstrap';
import SelectField from './SelectField';
import Checkbox from './Checkbox';
import axios from 'axios';
const SITE_KEY = "6LdOl6cUAAAAAMl_z4wEwQWjZoSAz6QZ3DuRjKtc";
import NumberFormat from 'react-number-format';
// import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "inline-block",
  margin: "0 10px",
  verticalAlign: "sub"
}

const DzCpraForm = ({page, setPage, userType, setUserType, formType, workerStatus, setWorkerStatus, issueType, setIssueType}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isSelected, setIsSelected] = useState('active');
  const [employmentStatus, setEmploymentStatus] = useState(null);
  const [firstname, setFirstName] = useState('');
  const [preferredName, setPreferredName] = useState('');
  const [lastname, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [ssn, setSsn] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [prevEmployer, setPrevEmployer] = useState('');
  const [applicationYear, setApplicationYear] = useState(null);
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [park, setPark] = useState(null);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [endYear, setEndYear] = useState(null);
  const [startYear, setStartYear] = useState(null);
  const [description, setDescription] = useState('');
  const [restrict, setRestrict] = useState('No');
  const [optOutSale, setOptOutSales] = useState('No');
  const [optOutAds, setOptOutAds] = useState('No');
  const [validated, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);


  const selectData = ["Glendale", "Hollywood Hills", "Long Beach", "Cathedral City", "Cypress", "Covina Hills", "Coachella Valley", "Indio", "San Gabriel", "Whittier"];
  const optOutRelationship = ["Current Worker", "Former Worker", "Contingent", "Vendor", "Job Applicant"];

  useEffect(() => {
    if(formType === "rtk-form"){
      if(workerStatus === "Consumer"){
        setIssueType("Consumer Request to Know")
      } else {
        setIssueType("Employee Request to Know")
      }
    }
    if(formType === "rtd-form"){
      if(workerStatus === "Consumer"){
        setIssueType("Consumer Request to Delete")
      } else {
        setIssueType("Employee Request to Delete")
      }
    }
    if(formType === "rtc-form"){
      if(workerStatus === "Consumer"){
        setIssueType("Consumer Request to Correct")
      } else {
        setIssueType("Employee Request to Correct")
      }
    }
    if(formType === "optOut-form"){
      if(workerStatus === "Consumer"){
        setIssueType("Consumer Request to Opt Out")
      } else {
        setIssueType("Employee Request to Opt Out")
      }
    }
  },[workerStatus])

  console.log(workerStatus);
  // console.log(formType);

  const sizes = {
    mobile: '600px',
    tablet: '768px'
  }
  const devices = {
    mobile: `(max-width: ${sizes.mobile})`,
    tablet: `(max-width: ${sizes.tablet})`
  }

  const formStyle = {
    'border': '1px solid #c5c5c5',
    'background-color': '#f2f3f3',
    'padding': '2.2rem',
    'text-align': 'left',
    'color': '#000000' // DZ.COM
  }
  const messageStyle = {
    margin: "1em auto",
    padding: "0.2em 1em",
    border: "2px solid #ffb900",
    };

  const primaryBtn = {
    // 'background-color': 'rgb(80, 115, 54)', FL.COM Green
    'background-color': '#103a71', // DZ.COM Blue
    'color': 'rgb(255, 255, 255)',
    'padding': '0.5rem 3rem',
  }

  const spinnerDiv = {
    "display": "inline-block",
    "vertical-align": "middle",
    "margin": "10px",
  }


  const chosenPark = (selectValue) => {
    setPark(selectValue);
  }

  const relationship = (selectValue) => {
    if(selectValue === 'Current Worker'){
      setWorkerStatus('Current Employee');
    } else if (selectValue === 'Former Worker') {
      setWorkerStatus('Former Employee');
    } else {
      setWorkerStatus(selectValue);
    }
  }

  const setCurrent = () => {
    setEmploymentStatus('active');
    setIsSelected('active');
    if(userType === "Worker"){
      setWorkerStatus("Current Employee")
    }
  }

  const setPast = () => {
    setEmploymentStatus('former');
    setIsSelected('former');
    if(userType === "Worker"){
      setWorkerStatus("Former Employee")
    }
  }

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = () => {
    // setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        // console.log(token);
        submitData(token);
      });
    });
  }

  // Send reCAPTCHA token to server for verification
  const submitData = async (token) => {

      try {
        const result = await axios.post('https://douglassandzook.com/wp-content/themes/sdwpd-bs4/recaptcha-react-forms.php', {
              token
            })
            // console.log(result.data.score);
            // sending the data to the wordpress backend to make the api call to Azure
            if(result.data.score > 0.5){
              try {
                const form = await axios.post('https://douglassandzook.com/wp-content/themes/sdwpd-bs4/JiraCpra.php', {
                    issueType,
                    firstname,
                    middleName,
                    lastname,
                    ssn,
                    phone,
                    email,
                    preferredName,
                    employmentStatus,
                    workerStatus,
                    prevEmployer,
                    park,
                    formType,
                    userType,
                    company,
                    title,
                    address,
                    city,
                    state,
                    zipcode,
                    endYear,
                    startYear,
                    applicationYear,
                    description,
                    optOutSale,
                    optOutAds,
                    restrict
                  })
                  console.log('returned data:', form);
                  // const data = form.data;

                  if (form.data.key) {
                    window.open("/privacy/cpra-thank-you/", "_self");
                    setSuccess(true);
                    setLoading(false);
                  }
              } catch (e) {
                console.log(`axios request failed: ${e}`);
                setError(true);
                setLoading(false);
              }
            }

          } catch (e){
            setRecaptchaError(true);
            console.log(`axios request failed: ${e}`);
          }
  }

  const sendToJira = async (event) => {
    event.preventDefault()
    const checkForm = event.target; //PRODUCTION
    // const checkForm = event.currentTarget; //Development
    if (checkForm.checkValidity() !== false) {
      event.stopPropagation();
      setError(false);
      setLoading(true);
      // submitData()
      handleReCaptchaVerify();
    } else {
      setError(true);
    } //end of form validity check
    setValidation(true);
  }

  // console.log(workerStatus);

  return (
    <>
      <div class="row">
        <div class="text-center" style={{"margin": "0 auto 30px auto"}}>{workerStatus === 'Consumer' ? 'In accordance with California law, in order to comply with your request, we are required to verify your identity with the information below, and ask that you complete this form.  Please provide your first and last names, email, and any additional contact information.' : 'Please provide the information requested below to verify your identity'}</div>
      </div>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <Form id="cpra-form" class="text-sm-center text-lg-left" style={formStyle} noValidate validated={validated} onSubmit={sendToJira}>
            {userType &&
              <div className="text-center" style={{"margin-bottom": "2rem"}}><h3>{userType} Form</h3></div>
            }
            {formType === 'optOut-form' && workerStatus !== 'Consumer' &&
              <div className="form-group">
                <label>Relationship with Forest Lawn*:</label>
                <SelectField value={optOutRelationship} selectInfo={relationship} style={{'background-color': '#103a71', 'color': '#FFFFFF' }}/>
              </div>
            }
            <div className="form-group">
              <label>{workerStatus !== 'Consumer' ? 'Legal' : ''} First Name*:</label>
              <input className="form-control form-control-lg" type="text" value={firstname} onChange={(e) => setFirstName(e.target.value)} required/>
              <div className="invalid-feedback">
                  Please provide a first name.
              </div>
            </div>
            {workerStatus === 'Consumer' &&
              <div className="form-group">
                <label> Middle Name:</label>
                <input className="form-control form-control-lg" type="text" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
              </div>
            }
            <div className="form-group">
              <label>{workerStatus !== 'Consumer' ? 'Legal' : ''} Last Name*:</label>
              <input className="form-control form-control-lg" type="text" value={lastname} onChange={(e) => setLastName(e.target.value)} required/>
              <div className="invalid-feedback">
                Please provide a last name.
              </div>
            </div>
            {workerStatus !== 'Consumer' &&
              <div className="form-group">
                <label>Preferred Name:</label>
                <input className="form-control form-control-lg" type="text" value={preferredName} onChange={(e) => setPreferredName(e.target.value)} />
              </div>
            }
            {/*userType === 'Worker' &&
              <div className="form-group">
                <label>Add the last 4-digits of SSN #:</label>
                <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="0000" type="text" value={ssn} format="####" onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  setSsn(formattedValue);
                }} />
              </div>
            */}
            {/*userType === 'Vendor' && formType !== 'optOut-form' &&
              <div className="form-group">
                <label>Company Name:</label>
                <input className="form-control form-control-lg" type="text" value={company} onChange={(e) => setCompany(e.target.value)} required/>
                <div className="invalid-feedback">
                  Please provide a Company name.
                </div>
              </div>
            */}
            <div className="form-group">
              <label>Phone Number*:</label>
              <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="(000) 000 - 0000" type="tel" value={phone} format="(###) ### - ####" onValueChange={(values) => {
                const { formattedValue, value } = values;
                setPhone(formattedValue);
              }} required/>
              <div className="invalid-feedback">
                Please provide a valid phone number.
              </div>
            </div>
            <div className="form-group">
              <label>{workerStatus !== 'Consumer' ? 'Personal' : ''} Email Address*:</label>
              <input className="form-control form-control-lg" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
              <div className="invalid-feedback">
                Please provide a valid email.
              </div>
            </div>
            {/*<div className="form-group">
              <label>Mailing Address:</label><br/>
              <input className="form-control form-control-lg" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
            </div>
            <div className="form-row">
              <div className="form-group col-md-5">
                <label>City:</label><br/>
                <input className="form-control form-control-lg" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
              </div>
              <div className="form-group col-md-4">
                <label>State:</label><br/>
                <input className="form-control form-control-lg" type="text" value={state} onChange={(e) => setState(e.target.value)} />
              </div>
              <div className="form-group col-md-3">
                <label>Zip Code:</label><br/>
                <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="00000" type="text" value={zipcode} format="#####" onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  // console.log(formattedValue);
                  setZipcode(formattedValue);
                }} />
              </div>
            </div>*/}
            {formType === 'optOut-form' &&
               <div className="form-group" style={{"margin-top": "2rem"}}>
                  <Checkbox
                    value=""
                    className=""
                    name="restrict"
                    label="Restrict Use and Disclosure of Sensitive Personal Information"
                    onChange={(e) => { e.target.checked ? setRestrict("Yes") : setRestrict("No") }}
                  />
                  <div class="form-group px30">
                    <p>By checking this box, I am requesting that Forest Lawn limit its use and disclosure of my sensitive personal information to:</p>
                    <ul class="px15" style={{"list-style":"inherit"}}>
                      <li>Ensuring safety and integrity to a reasonably necessary and proportionate extent </li>
                      <li>Performing services or providing goods reasonably expected based on my requests for them</li>
                      <li>Short-term transient use</li>
                      <li>Performing services on behalf of the business</li>
                      <li>Verifying or maintaining quality control or</li>
                      <li>Purposes otherwise authorized by law.</li>
                    </ul>
                  </div>
                  <Checkbox
                    value=""
                    style={{"margin-top": "1rem"}}
                    className=""
                    name="optOut"
                    label="Opt-Out of Sale"
                    onChange={(e) => { e.target.checked ? setOptOutSales("Yes") : setOptOutSales("No") }}
                  />
                  <Checkbox
                    value=""
                    style={{"margin-top": "1rem"}}
                    className=""
                    name="optOut"
                    label="Opt-Out of Use for Cross-Context Behavioral Advertisements"
                    onChange={(e) => { e.target.checked ? setOptOutAds("Yes") : setOptOutAds("No") }}
                  />
                </div>
              }
              {formType !== 'optOut-form' && workerStatus !== 'Consumer' &&
               <div>
                    <div className="form-group">
                      <label>{userType === 'Worker' ? 'Latest Job Title and/or Department:' : userType === 'Vendor' ? 'Latest Forest Lawn Department:' : 'Latest Job Title and/or Department of Interest'}*</label><br/>
                      <input className="form-control form-control-lg" type="text" value={title} onChange={(e) => setTitle(e.target.value)} required/>
                      <div className="invalid-feedback">
                        Please enter a Job Title or Department.
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{userType === 'Job Applicant' ? 'Latest Park Location of Interest*' : 'Latest Park Location*:'}</label>
                      <SelectField value={selectData} selectInfo={chosenPark} style={{'background-color': '#103a71', 'color': '#FFFFFF' }} />
                    </div>
                    {userType !== 'Job Applicant' &&
                    <div>
                      <div className="form-group">{/*Vendor & Worker only / radio button*/}
                        <label>{userType === 'Worker' ? 'Employment ' : ''}Status:</label><br/>
                      </div>
                      <div className="form-group" style={{"margin-bottom": "2rem"}}>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="status" id="activeRadio" value="active" onClick={setCurrent} checked={isSelected === 'active'}/>
                          <label style={{"margin-left":"3px", "vertical-align": "top"}} class="form-check-label" for="activeRadio">
                            Current {userType}
                          </label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="status" id="formerRadio" value="former" onClick={setPast} checked={isSelected === 'former'}/>
                          <label style={{"margin-left":"3px", "vertical-align": "top"}} class="form-check-label" for="formerRadio">
                            Past {userType}
                          </label>
                        </div>
                        {userType === 'Worker' &&
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="status" id="contingentRadio" value="contingent" onClick={() => { setWorkerStatus('Contingent'); setIsSelected('contingent') }} checked={isSelected === 'contingent'}/>
                            <label style={{"margin-left":"3px", "vertical-align": "top"}} class="form-check-label" for="contingentRadio">
                              Contingent Worker
                            </label>
                          </div>
                        }
                        </div>
                      </div>
                    }
                    {/*userType !== 'Job Applicant'
                    ? <div>
                        <div className="form-group">{//Vendor & Worker only}
                          <label>Employment Start Year:</label><br/>{//numeric value no greater than current year}
                          <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="0000" min="1900" max="2022" type="text" value={startYear} format="####" onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            console.log(formattedValue);
                            setStartYear(formattedValue);
                          }} />
                        </div>
                        <div className="form-group">{//Vendor & Worker only / radio button}
                          <label>{userType === 'Worker' ? 'Employment ' : ''}Status:</label><br/>
                        </div>
                        <div className="form-group" style={{"margin-bottom": "2rem"}}>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="status" id="activeRadio" value="active" onClick={setCurrent} checked={isSelected === 'active'}/>
                            <label style={{"margin-left":"3px", "vertical-align": "top"}} class="form-check-label" for="activeRadio">
                              Current {userType}
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="status" id="formerRadio" value="former" onClick={setPast} checked={isSelected === 'former'}/>
                            <label style={{"margin-left":"3px", "vertical-align": "top"}} class="form-check-label" for="formerRadio">
                              Past {userType}
                            </label>
                          </div>
                          {userType === 'Worker' &&
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="status" id="contingentRadio" value="contingent" onClick={() => { setWorkerStatus('contingent'); setIsSelected('contingent') }} checked={isSelected === 'contingent'}/>
                              <label style={{"margin-left":"3px", "vertical-align": "top"}} class="form-check-label" for="contingentRadio">
                                Contingent Worker
                              </label>
                            </div>
                          }
                        </div>
                        {employmentStatus === "former" &&
                          <div className="form-group">{//Vendor & Employee only / if former Vendor or employee}
                            <label>Employment End Year:</label><br/>{//numeric value no greater than current year}
                            <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="0000" type="text" value={endYear} min="1900" max="2022" format="####" onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              setEndYear(formattedValue);
                            }} />
                          </div>
                        }
                    </div>
                : <div>
                    <div className="form-group">
                      <label>Latest Job Application Year:</label><br/>{//numeric value no greater than current year}
                      <input className="form-control form-control-lg" type="text" value={applicationYear} onChange={(e) => setApplicationYear(e.target.value)} />
                    </div>
                    <div className="form-group">
                      <label>Previous Employer Listed on Application:</label><br/>{//numeric value no greater than current year}
                      <input className="form-control form-control-lg" type="text" value={prevEmployer} onChange={(e) => setPrevEmployer(e.target.value)} />
                    </div>
                  </div>
                  }
                  {formType === 'rtc-form' &&
                    <div className="form-group">
                      <label>Additional Information*:</label><br/>
                      <textarea className="form-control form-control-lg" rows="6" value={description} onChange={(e) => setDescription(e.target.value)} required/>
                      <div className="invalid-feedback">
                        Please enter Information to Correct.
                      </div>
                    </div>
                  */}
              </div>
            }
            <p class="margin-bottom-15">* required</p>

            <div id="recaptcha"></div>
            <div class="text-center">
              <button className="btn justify-content-center font-18" style={primaryBtn} type="submit">
                SUBMIT
                <ClipLoader color={'rgb(255, 255, 255)'} loading={loading} cssOverride={override} size={20} />
              </button>
            </div>
            <div className="error">
              {error &&
                <p style={messageStyle}>One or more fields have an error. Please check and try again.</p>
              }
            </div>
            <div>
              {recaptchaError &&
                <div><p style={messageStyle}>There is an error with your request. Please try again later.</p></div>
              }
            </div>
          </Form>
        </div>
        <div className="col-md-2"></div>
      </div>
    </>

  )
};

export default DzCpraForm;
