const { useState, useEffect, useCallback, CSSProperties, useRef } = wp.element;
import { Form } from 'react-bootstrap';
import SelectField from './SelectField';
import axios from 'axios';
const SITE_KEY = "6LdOl6cUAAAAAMl_z4wEwQWjZoSAz6QZ3DuRjKtc";
import NumberFormat from 'react-number-format';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Input from "./Input";


const override = css`
  display: inline-block;
  margin: 0 auto;
`;

const BuybackForm = () => {
  const [propertyOwnerFirstname, setPropertyOwnerFirstName] = useState('');
  const [propertyOwnerLastname, setPropertyOwnerLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [contactFirstname, setContactFirstname] = useState('');
  const [contactLastname, setContactLastname] = useState('');
  const [additionalOwners, setAdditionalOwner] = useState([]);
  const [relationTo, setRelationTo] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [park, setPark] = useState('');
  const [section, setSection] = useState('');
  const [lotNum, setLotNum] = useState('');
  const [spaceNum, setSpaceNum] = useState('');
  const [description, setDescription] = useState('');
  const [validated, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [propertyOwners, setPropertyOwners] = useState([]);
  const [toggle, setToggle] = useState(false);

  const inputRef = useRef();
  const selectRef = useRef();

  const handleChange = (e, index) => {
    const values = [...propertyOwners];
    console.log(propertyOwners);
    console.log(values[index].value);
    values[index].value = e.target.value;
    setPropertyOwners(values);
  };

  const handleAddField = (e) => {
    e.preventDefault();
    const values = [...propertyOwners];
    console.log(values);
    values.push({
      value: "",
    });
    setPropertyOwners(values);
    setToggle(false);
  };

  const handleDeleteField = (e, index) => {
    const values = [...propertyOwners];
    values.splice(index, 1);
    setPropertyOwners(values);
  };

  const addBtnClick = (e) => {
    e.preventDefault();
    setToggle(true);
  };

  const selectData = ["Glendale", "Hollywood Hills", "Long Beach", "Cathedral City", "Cypress", "Covina Hills"];
  const formStyle = {
    'border': '1px solid #c5c5c5',
    'background-color': '#f2f3f3',
    'padding': '2.2rem'
  }
  const messageStyle = {
    margin: "1em auto",
    padding: "0.2em 1em",
    border: "2px solid #ffb900",
    };

  const primaryBtn = {
    'background-color': 'rgb(80, 115, 54)',
    'color': 'rgb(255, 255, 255)',
    'padding': '0.5rem 3rem',
    'font-size': '1.5rem'
  }

  const spinnerDiv = {
    "display": "inline-block",
    "vertical-align": "middle",
    "margin": "10px",
  }


  const chosenPark = (selectValue) => {
    setPark(selectValue);
  }

  const _resetForm = () => {
    setPropertyOwnerFirstName('');
    setPropertyOwnerLastName('');
    setContactFirstname('');
    setContactLastname('');
    setRelationTo('');
    setPhone('');
    setEmail('');
    setPark('');
    setSection('');
    setLotNum('');
    setSpaceNum('');
    setDescription('');
  }

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = () => {
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        console.log(token);
        submitData(token);
      });
    });
  }

  // Send reCAPTCHA token to server for verification
  const submitData = async (token) => {
      try {
            const result = await axios.post('http://forestlawn.web01.forestlawn.intra/wp-content/themes/forestlawn/recaptcha-react-forms.php', {
            // const result = await axios.post('http://localhost/forestlawn.com/wp-content/themes/forestlawn/recaptcha-react-forms.php', {
              token
            })
            console.log(result.data.success);
            // sending the data to the wordpress backend to make the api call to Azure
            if(result.data.success){
              try {
                // const form = await axios.post('https://forestlawn.com/wp-content/themes/forestlawn/JiraBuybackRequest.php', {
                const form = await axios.post('http://forestlawn.web01.forestlawn.intra/wp-content/themes/forestlawn/JiraBuybackRequest.php', {
                  // const form = await axios.post('http://localhost/forestlawn.com/wp-content/themes/forestlawn/JiraBuybackRequest.php', {
                    propertyOwnerFirstname,
                    propertyOwnerLastname,
                    address,
                    city,
                    state,
                    zipcode,
                    contactFirstname,
                    contactLastname,
                    relationTo,
                    phone,
                    email,
                    park,
                    section,
                    lotNum,
                    spaceNum,
                    description
                  })
                  console.log('returned data:', form);
                  // const data = form.data;
                  if (form.data.key) {
                    window.open("/thank-you", "_self");
                    setSuccess(true);
                    setLoading(false);
                  }
              } catch (e) {
                console.log(`axios request failed: ${e}`);
                setError(true);
                setLoading(false);
              }
            }
            setRecaptchaError(true);
          } catch (e){
            console.log(`axios request failed: ${e}`);
          }
  }

  const sendToJira = async (event) => {
    event.preventDefault()
    const checkForm = event.target; //PRODUCTION
    // const checkForm = event.currentTarget; //Development
    if (checkForm.checkValidity() !== false) {
      event.stopPropagation();
      setError(false);
      setLoading(true);
      handleReCaptchaVerify();
    } else {
      setError(true);
    } //end of form validity check
    setValidation(true);
  }

  const addMoreOwners = (e) => {
    e.preventDefault();
    console.log('ill add more');

  }


  return (
    <Form id="buyback-form" style={formStyle} noValidate validated={validated} onSubmit={sendToJira}>
      {success &&
        <div><p style={messageStyle}>Thank you for your message. It has been sent.</p></div>
      }
      {propertyOwners.map((obj, index) => (
          <Input
            key={index}
            objValue={obj}
            onChange={handleChange}
            index={index}
            deleteField={handleDeleteField}
          />
        ))}
        <button className="add-btn" onClick={handleAddField}>
          Add New
        </button>
      <div className="form-group">
        <label>Property Owner(s)’ First Name*:</label>
        <input className="form-control form-control-lg" type="text" value={propertyOwnerFirstname} onChange={(e) => setPropertyOwnerFirstName(e.target.value)} required/>
        <div className="invalid-feedback">
            Please provide a first name.
        </div>
      </div>
      <div className="form-group">
        <label>Property Owner(s)’ Last Name*:</label>
        <input className="form-control form-control-lg" type="text" value={propertyOwnerLastname} onChange={(e) => setPropertyOwnerLastName(e.target.value)} required/>
        <div className="invalid-feedback">
          Please provide a last name.
        </div>
      </div>
      <div className="form-group">
        <button className="btn success" onClick={addMoreOwners}>Add Another Owner</button>
      </div>
      <div className="form-group">
        <label>Your first name*:</label><br/>
        <input className="form-control form-control-lg" type="text" value={contactFirstname} onChange={(e) => setContactFirstname(e.target.value)} required/>
        <div className="invalid-feedback">
          Please provide your first name.
        </div>
      </div>
      <div className="form-group">
        <label> Your last name*:</label><br/>
        <input className="form-control form-control-lg" type="text" value={contactLastname} onChange={(e) => setContactLastname(e.target.value)} required/>
        <div className="invalid-feedback">
          Please provide your last name.
        </div>
      </div>
      <div className="form-group">
        <label>Your relation to property owner*:</label>
        <input className="form-control form-control-lg" type="text" value={relationTo} onChange={(e) => setRelationTo(e.target.value)} required/>
        <div className="invalid-feedback">
          Please provide your relationship to property owner.
        </div>
      </div>
      <div className="form-group">
        <label>Your phone number*:</label>
        <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="(000) 000 - 0000" type="tel" value={phone} format="(###) ### - ####" onValueChange={(values) => {
          const { formattedValue, value } = values;
          setPhone(formattedValue);
        }} required/>
        <div className="invalid-feedback">
          Please provide a valid phone number.
        </div>
      </div>
      <div className="form-group">
        <label>Your email*:</label>
        <input className="form-control form-control-lg" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
        <div className="invalid-feedback">
          Please provide a valid email.
        </div>
      </div>
      <div className="form-group">
        <label>Mailing Address:</label><br/>
        <input className="form-control form-control-lg" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-5">
          <label>City:</label><br/>
          <input className="form-control form-control-lg" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
        </div>
        <div className="form-group col-md-4">
          <label>State:</label><br/>
          <input className="form-control form-control-lg" type="text" value={state} onChange={(e) => setState(e.target.value)} />
        </div>
        <div className="form-group col-md-3">
          <label>Zip Code:</label><br/>
          <NumberFormat className="form-control form-control-lg" displayType="input" placeholder="00000" type="text" value={zipcode} format="#####" onValueChange={(values) => {
            const { formattedValue, value } = values;
            // console.log(formattedValue);
            setZipcode(formattedValue);
          }} />
        </div>
      </div>
      <div className="form-group">
        <label>Park Location*:</label>
        <SelectField value={selectData} selectInfo={chosenPark} />
      </div>
      <div className="form-group">
        <label>Cemetery section:</label><br/>
        <input className="form-control form-control-lg" type="text" value={section} onChange={(e) => setSection(e.target.value)} />
      </div>
      <div className="form-group">
        <label>Cemetery lot/crypt/niche number:</label><br/>
        <input className="form-control form-control-lg" type="text" value={lotNum} onChange={(e) => setLotNum(e.target.value)} />
      </div>
      <div className="form-group">
        <label>Space number(s):</label><br/>
        <input className="form-control form-control-lg" type="text" value={spaceNum} onChange={(e) => setSpaceNum(e.target.value)} />
      </div>
      <div className="form-group">
        <label>Other information:</label><br/>
        <textarea className="form-control form-control-lg" rows="6" value={description} onChange={(e) => setDescription(e.target.value)}/>
      </div>
      <p class="margin-bottom-15">* required</p>
      <p class="margin-bottom-15">
      By submitting this information, I certify that I am an owner of record or an authorized representative of an owner of record, and as such, I am legally entitled to receive the requested information. I agree to indemnify, protect, defend and hold Forest Lawn Memorial-Park Association harmless from and against all claims and damages, including attorneys’ fees and costs, that may result from this certification and Forest Lawn’s provision to me of the information that I have requested.</p>
      <p class="margin-bottom-15">
      If you are not an owner of record but are an heir or other successor in interest to the property, and you would like information about transferring the property into your name, please do not submit your information above. Please click <a target="_blank" href="http://forestlawn.web01.forestlawn.intra/transfers-contact-form/">http://forestlawn.web01.forestlawn.intra/transfers-contact-form/</a></p>
      <p class="margin-bottom-15">
      We respect your privacy and will not sell your personal information. By submitting this form, you AGREE that Forest Lawn will collect and use the information you provide here to periodically email, call text or message you with information about products, services, and events according to the terms of the Forest Lawn Privacy Policy and Terms of Use at www.forestlawn.com/privacy until you change your communication preferences at <a target="_blank" href="forestlawn.web01.forestlawn.intra/preferences">www.forestlawn.com/preferences</a></p>
      <div id="recaptcha"></div>
      <button className="btn" style={primaryBtn} type="submit">Submit</button>
      <div style={spinnerDiv}>
        <ClipLoader color={'rgb(80, 115, 54)'} loading={loading} css={override} size={25} />
      </div>
      <div className="error-message">
        {error &&
          <p style={messageStyle}>One or more fields have an error. Please check and try again.</p>
        }
      </div>
      <div>
        {success &&
          <div><p style={messageStyle}>Thank you for your message. It has been sent.</p></div>
        }
      </div>
      <div>
        {recaptchaError &&
          <div><p style={messageStyle}>There is an error with your reuest. Please try again later.</p></div>
        }
      </div>
    </Form>
  )
};

export default BuybackForm;
